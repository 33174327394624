<template>
  <!-- <div></div> -->
  <suy-table :reload="loadData" v-model:columns="columns">
    <template #search>
      <a-row :gutter="[24,6]" justify="start">

        <a-button type="primary" @click="onModel(1,'')">添加渠道大类</a-button>
      </a-row>
      <a-modal v-model:visible="searchAddShow" width="600px" title="新增" @ok="onAddForm">
        <div>
          <a-form layout="horizontal" ref="formRef" :model="addForm">
            <a-form-item label="渠道名称">
              <a-input v-model:value="addForm.name" />
            </a-form-item>
            <a-form-item label="渠道编码">
              <a-input v-model:value="addForm.code" />
            </a-form-item>
            <a-form-item label="渠道排序">
              <a-input v-model:value="addForm.sort" placeholder="数字1最大" />
            </a-form-item>
            <a-form-item label="主叫信息">
              <a-input v-model:value="addForm.relateCall" />
            </a-form-item>
          </a-form>
        </div>
      </a-modal>
    </template>

    <a-table @expand="onExpand" :columns="columns" :row-key="record => record.id" :data-source="listData" :loading="loading">
      <template #operation="{record}">
        <a-space>
          <a @click="onModel(2,record)" v-if="record.children!=null">
            添加子分类
          </a>
          <a @click="onModelEdit(record)" :class="record.children!=null?'':'color-339933'">
            编辑名称
          </a>
          <a-popconfirm title="是否确认删除" ok-text="是" cancel-text="否" @confirm="deleteChannel(record)">
            <a :class="record.children!=null?'':'color-339933'">
              删除
            </a>
          </a-popconfirm>
        </a-space>
      </template>
      <template #quit="{record}">
        <a-popconfirm :title="`你确定${record.isEnable ? '禁用':'启用'}该渠道吗？`" @confirm="adjustment(record)">
          <a-switch v-model:checked="record.isEnable" checkedChildren="启用" unCheckedChildren="禁用" @click="record.isEnable=!record.isEnable" />
        </a-popconfirm>
      </template>

    </a-table>
  </suy-table>
  <a-modal v-model:visible="editShow" width="600px" title="修改" @ok="onEdit">
    <div>
      <a-form layout="horizontal" :model="editForm">
        <a-form-item label="渠道名称">
          <a-input v-model:value="editForm.name" />
        </a-form-item>
        <a-form-item label="渠道排序">
          <a-input v-model:value="editForm.sort" />
        </a-form-item>

      </a-form>
    </div>
  </a-modal>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import SuyTable from '@/components/SuyTable'
import { channelList, enable as enableMethod, addCategory, addSub, edit, channelDelete } from '@/api/marketing/channel'
import { message } from 'ant-design-vue'
export default {
  components: {
    SuyTable
  },
  setup () {
    const state = reactive({
      loading: false,
      searchAddShow: false,
      editShow: false,
      addForm: {
        channelId: ''
      },
      editForm: {
        name: '',
        sort: 1,
        channelId: ''
      },
      addType: 1,
      onExpandKeyId: 0,
      record: {},
      listData: [],
      columns: [
        {
          title: '渠道名称',
          dataIndex: 'name'
        },
        {
          title: 'id',
          dataIndex: 'id'
        },
        {
          title: '渠道编码',
          dataIndex: 'code'
        },
        {
          title: '排序',
          dataIndex: 'sort'
        },
        {
          title: '最近操作时间',
          dataIndex: 'updateTime'
        },
        {
          title: '操作人',
          dataIndex: 'operator'
        },
        {
          title: '主叫信息',
          dataIndex: 'relateCall'
        },
        {
          title: '使用状态',
          dataIndex: 'isEnable',
          slots: {
            customRender: 'quit'
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          slots: {
            customRender: 'operation'
          }
        }
      ]

    })
    onMounted(() => {
      loadData()
    })
    const searchData = () => {
      loadData()
    }
    const loadData = () => {
      state.loading = true
      channelList({ channelLevel: 1 }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data
          state.listData.forEach(e => {
            if (e.isEnable.value === 1) {
              e.isEnable = true
            } else {
              e.isEnable = false
            }
            e.children = []
          })
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        state.loading = false
      })
    }
    const adjustment = (e) => {
      enableMethod(e.id, Number(!e.isEnable))
        .then(res => {
          if (res.code === 10000) {
            e.isEnable = !e.isEnable
            message.success(res.msg)
          }
        })
    }
    const onAddForm = () => {
      if (state.addType === 1) {
        addCategory(state.addForm).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            loadData()
            state.searchAddShow = false
            state.addForm = {}
          }
        })
      } else {
        addSub(state.addForm).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            // 当子分类打开，且对应添加子分类一致时才 更新页面子分类list
            if (state.record.id === state.onExpandKeyId) {
              getSubList(state.record)
            }
            state.searchAddShow = false
            state.addForm = {}
          }
        })
      }
    }
    // 树结构被点击时触发
    const onExpand = (expanded, record) => {
      if (expanded === true) {
        state.onExpandKeyId = record.id
        getSubList(record)
      }
    }
    const getSubList = (record) => {
      record.children = [{
        channelId: null,
        code: '加载中',
        createTime: null,
        id: '',
        isEnable: { value: 0, label: '否' },
        name: '加载中',
        operator: '加载中',
        relateCall: '加载中',
        updateTime: null
      }]
      channelList({ channelLevel: 2, channelId: record.id }).then(res => {
        if (res.code === 10000) {
          res.data.forEach(e => {
            if (e.isEnable.value === 1) {
              e.isEnable = true
            } else {
              e.isEnable = false
            }
          })
          record.children = res.data
          console.log(state.listData);
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        state.loading = false
      })
    }
    // 判断是大类还是小类
    const onModel = (e, record) => {
      state.searchAddShow = true
      if (e === 1) {
        state.addType = 1
        state.addForm.channelId = ''
      } else {
        state.addType = 2
        state.addForm.channelId = record.id
        state.record = record
      }
    }
    // 判断是大类还是小类
    const onModelEdit = (e) => {
      state.editShow = true
      state.editForm.name = e.name
      state.editForm.id = e.id
      state.editForm.sort = e.sort
      state.editForm.channelId = e.channelId
    }
    // 修改名称
    const onEdit = () => {
      edit(state.editForm).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.editShow = false
          if (state.editForm.channelId != null) {
            // 寻找大类，保证只刷新该大类的数据
            const bigRecord = state.listData.filter(item => item.id === state.editForm.channelId)
            if (bigRecord.length > 0) {
              getSubList(bigRecord[0])
            }
          } else {
            loadData()
          }
        }
      })
    }
    // 删除渠道分类
    const deleteChannel = record => {
      channelDelete(record.id).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          if (record.channelLevel.value === 1) {
            loadData()
          } else if (record.channelLevel.value === 2) {
            // 寻找大类，保证只刷新该大类的数据
            const bigRecord = state.listData.filter(item => item.id === record.channelId)
            if (bigRecord.length > 0) {
              getSubList(bigRecord[0])
            }
          }
        }
      })
    }
    return {
      ...toRefs(state),
      searchData,
      adjustment,
      onAddForm,
      onExpand,
      onModel,
      onModelEdit,
      onEdit,
      deleteChannel
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
